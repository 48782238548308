import React from 'react'
import { Typography } from '@mui/material'
import FAQ from '@talentinc/gatsby-theme-ecom/components/FAQ'
import { useTranslation } from 'react-i18next'
import useBrand from '../../hooks/useBrand'

function ResumeReviewFAQ() {
  const { t } = useTranslation()
  const { flagshipProduct, display_name: brandName } = useBrand()

  const items = t('resumeReview.faq.items', {
    returnObjects: true,
    defaultValue: [],
    flagshipProduct,
    brandName,
  }) as { question: string; answer: string }[]

  return (
    <div>
      <Typography variant="h2">{t('resumeReview.faq.title')}</Typography>

      {items.map(({ question, answer }, index) => {
        const id = `${index + 1}`

        return (
          <FAQ
            __typename="ContentfulFrequentlyAskedQuestion"
            name="resume-review-faq"
            key={`resume-review-faq-${id}`}
            contentful_id={id}
            question={question as any}
            answer={answer as any}
          />
        )
      })}
    </div>
  )
}

export default ResumeReviewFAQ

import React from 'react'
import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import LinkButton from '@talentinc/gatsby-theme-ecom/components/Link/LinkButton'
import { useTranslation } from 'react-i18next'
import { useResumeReviewStyles } from './styles'
import useBrand from '../../hooks/useBrand'

const image =
  'https://images.ctfassets.net/7thvzrs93dvf/od86iYjOxmosvOG8ZuJKD/64d7e0d3cf190bd0604a37daef472ef8/what_does_ats_see.webp'
const imageMobile =
  'https://images.ctfassets.net/7thvzrs93dvf/3BGB6Xp0YJfjjC8qQ4Ojvp/434ab0c8ebf42eab646633bc03fbd1c5/what_does_ats_see_mobile.webp'

export default function ResumeReviewATS() {
  const { classes, cx } = useStyles()
  const { classes: sharedClasses } = useResumeReviewStyles()
  const { t } = useTranslation()
  const { flagshipProduct } = useBrand()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'))

  const imageSrc = isMobile ? imageMobile : image

  const buttonLabel = isMobile
    ? 'resumeReview.ats.uploadResumeMobileBtn'
    : 'resumeReview.ats.uploadResumeBtn'

  return (
    <div className={classes.container}>
      <div className={cx(classes.column, classes.left)}>
        <img src={imageSrc} alt="What does ATS see" />
      </div>
      <div className={cx(classes.column, classes.right)}>
        <Typography variant="h2" className={sharedClasses.title}>
          {t('resumeReview.ats.header')}
        </Typography>
        <Typography variant="body1" fontWeight="500" whiteSpace="pre-wrap">
          {t('resumeReview.ats.description1')}
          <br />
          <br />
        </Typography>
        <Typography variant="body1" whiteSpace="pre-wrap">
          {t('resumeReview.ats.description2', { flagshipProduct })}
        </Typography>
        <div className={classes.button}>
          <LinkButton
            variant="contained"
            fullWidth
            onClick={(e) => {
              e.preventDefault()
              window.scrollTo({
                top: 0, // Scroll to the top of the page
                behavior: 'smooth', // Enables smooth scrolling
              })
            }}
          >
            {t(buttonLabel, { flagshipProduct })}
          </LinkButton>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
    [theme.breakpoints.down('tablet')]: {
      flexDirection: 'column',
      background: theme.colors.blue[10],
      borderRadius: 24,
      gap: 0,
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  left: {
    [theme.breakpoints.up('tablet')]: {
      backgroundColor: theme.colors.neutral[5],
      borderRadius: 24,
      padding: 20,
    },
    '& img': {
      width: '100%',
    },
  },
  right: {
    padding: 20,
  },
  button: {
    marginTop: theme.spacing(3),
  },
}))

import React from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import IntervalSlider, {
  IntervalSliderItem,
} from '@talentinc/gatsby-theme-ecom/components/IntervalSlider/IntervalSlider'
import { useResumeReviewStyles } from './styles'
import { useTranslation } from 'react-i18next'
import useBrand from '../../hooks/useBrand'

export default function ResumeReviewWhatToExpect() {
  const { classes } = useStyles()
  const { classes: sharedClasses } = useResumeReviewStyles()
  const { t } = useTranslation()
  const { flagshipProduct } = useBrand()

  const items: IntervalSliderItem[] = (
    t('resumeReview.whatToExpect.items', {
      returnObjects: true,
      defaultValue: [],
      flagshipProduct,
    }) as { title: string; description: string; img: string }[]
  )
    // map function adds `id` field, as required in IntervalSliderItem
    .map((item, index) => ({
      id: index + 1,
      ...item,
    }))

  return (
    <div className={classes.sectionWhatToExpect}>
      <Typography align="center" variant="h2" className={sharedClasses.title}>
        {t('resumeReview.whatToExpect.title', { flagshipProduct })}
      </Typography>
      <Typography align="center" variant="body1" className={sharedClasses.subtitle}>
        {t('resumeReview.whatToExpect.subtitle', { flagshipProduct })}
      </Typography>
      <IntervalSlider
        tabTimeOutInSecond={10}
        items={items}
        classes={{
          step: classes.sliderItemClass,
          activeStep: classes.sliderActiveItemClass,
        }}
      />
    </div>
  )
}

export const useStyles = makeStyles()((theme) => ({
  sectionWhatToExpect: {
    backgroundColor: theme.colors.neutral[5],
    paddingBlock: theme.spacing(6),
    paddingInline: 109,
    borderRadius: 40,
    [theme.breakpoints.down('tablet')]: {
      paddingInline: 54,
    },
  },
  sliderItemClass: {
    background: theme.colors.neutral[10],
  },
  sliderActiveItemClass: {
    background: theme.colors.blue[250],
  },
}))

import { brand } from './common/brand'
import { footer } from './common/footer'
import { headerV2 } from './common/headerV2'

export default {
  title: 'Free CV Review',
  slug: 'cv-review',
  seo: {
    pageTitle: 'Free CV Review',
    description:
      'Is your CV strong enough to land your dream job? Upload your CV today and get free feedback from one of CV expert CV writers.',
    openGraphTitle: null,
    openGraphDescription: null,
    openGraphImage: null,
    noIndex: false,
    doNotDeployToProduction: false,
    pageType: null,
    canonicalUrl: null,
  },
  ...headerV2,
  ...brand,
  ...footer,
}

import React from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useResumeReviewStyles } from './styles'
import useBrand from '../../hooks/useBrand'
import { useTranslation } from 'react-i18next'

export default function ResumeReviewHowItWorks() {
  const { classes } = useStyles()
  const { classes: sharedClasses } = useResumeReviewStyles()
  const { t } = useTranslation()
  const { flagshipProduct } = useBrand()

  const items: { title: string; subtitle: string; image: string }[] = t(
    'resumeReview.howItWorks.items',
    { returnObjects: true, defaultValue: [], flagshipProduct }
  )

  return (
    <>
      <Typography align="center" variant="h2" className={sharedClasses.title}>
        {t('resumeReview.howItWorks.title', { flagshipProduct })}
      </Typography>
      <Typography align="center" variant="body1" className={sharedClasses.subtitle}>
        {t('resumeReview.howItWorks.subtitle')}
      </Typography>
      <div className={classes.itemsWrapper}>
        {items.map(({ title, subtitle, image }, index) => {
          return (
            <div key={index} className={classes.itemWrapper}>
              <div className={classes.itemTextWrapper}>
                <Typography
                  align="center"
                  variant="h3"
                  className={classes.itemTitle}
                >
                  {title}
                </Typography>
                <Typography
                  align="center"
                  variant="body1"
                  className={classes.itemSubtitle}
                >
                  {subtitle}
                </Typography>
              </div>

              <img src={image} alt={title} className={classes.itemImage} />
            </div>
          )
        })}
      </div>
    </>
  )
}

export const useStyles = makeStyles()((theme) => ({
  itemsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    gap: '32px',
    [theme.breakpoints.down(1024)]: {
      gap: '24px',
    },
    [theme.breakpoints.down('tablet')]: {
      gap: '16px',
      gridTemplateColumns: '1fr',
    },
  },
  itemWrapper: {
    backgroundColor: theme.colors.neutral[5],
    borderRadius: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  itemTextWrapper: {
    padding: theme.spacing(4, 6),
    flex: 1,
    flexBasis: 0,
    [theme.breakpoints.down(1024)]: {
      padding: theme.spacing(3, 2),
    },
  },
  itemTitle: {
    fontFamily: 'tt-talent',
    fontWeight: 400,
    fontSize: 23,
    lineHeight: '28px',
    color: theme.colors.neutral[90],
    marginBottom: 8,
  },
  itemSubtitle: {
    fontFamily: 'tt-talent',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    color: theme.colors.neutral[60],
  },
  itemImage: {
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto',
    [theme.breakpoints.down('tablet')]: {
      maxWidth: 360,
    },
  },
}))

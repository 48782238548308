import React from 'react'
import Footer from '@talentinc/gatsby-theme-ecom/components/Footer/Footer'
import { PageContextType } from '@talentinc/gatsby-theme-ecom/types/page'
import FooterCTA from '@talentinc/gatsby-theme-ecom/components/FooterCTA/FooterCTA'
import TestimonialsV2 from '@talentinc/gatsby-theme-ecom/components/TestimonialsV2/TestimonialsV2'
import {
  ResumeReviewATS,
  ResumeReviewFAQ,
  ResumeReviewHero,
  ResumeReviewWhatToExpect,
  ResumeReviewHowItWorks,
} from '@talentinc/gatsby-theme-ecom/components/ResumeReview'
import Section from '@talentinc/gatsby-theme-ecom/components/Section/Section'
import TopLayout from '@talentinc/gatsby-theme-ecom/layouts/TopLayout'

type Props = {
  pageData: any
  pageContext: PageContextType
}

const ResumeReviewPage = ({ pageData, pageContext }: Props) => {
  return (
    <TopLayout pageData={pageData} pageContext={pageContext}>
      <ResumeReviewHero />

      <Section>
        <ResumeReviewHowItWorks />
      </Section>

      <Section innerPadded>
        <TestimonialsV2 />
      </Section>

      <Section>
        <ResumeReviewWhatToExpect />
      </Section>

      <Section innerPadded>
        <ResumeReviewATS />
      </Section>

      <Section innerPadded>
        <ResumeReviewFAQ />
      </Section>

      <FooterCTA />
      <Footer useSimulatedPageLoad={false} />
    </TopLayout>
  )
}

export default ResumeReviewPage

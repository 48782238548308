import React from 'react'
import RoundBackgroundBlock from '@talentinc/gatsby-theme-ecom/components/RoundBackgroundBlock/RoundBackgroundBlock'
import { RBBVariants } from '@talentinc/gatsby-theme-ecom/types/landingPageV2'
import { BindToFields } from '@talentinc/gatsby-theme-ecom/types/contentfulAttributes'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import useBrand from '../../hooks/useBrand'

function ResumeReviewHero() {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { flagshipProduct } = useBrand()

  const uploadResumeData = {
    codeClasses: null,
    variant: RBBVariants.HeroCentered,
    content: {
      raw: `{
        "nodeType": "document",
        "data":
        {},
        "content": [
        {
          "nodeType": "heading-1",
          "data":
          {},
          "content": [
          {
            "nodeType": "text",
            "value": "${t('resumeReview.hero.title', { flagshipProduct })}",
            "marks": [],
            "data":
            {}
          }]
        },
        {
          "nodeType": "paragraph",
          "data":
          {},
          "content": [
          {
            "nodeType": "text",
            "value": "${t('resumeReview.hero.subtitle', { flagshipProduct })}",
            "marks": [],
            "data":
            {}
          }]
        },
        {
          "nodeType": "paragraph",
          "data":
          {},
          "content": [
          {
            "nodeType": "text",
            "value": "",
            "marks": [],
            "data":
            {}
          }]
        },
        {
          "nodeType": "embedded-entry-block",
          "data":
          {
            "target":
            {
              "sys":
              {
                "id": "2b2viIpoblj1paxLtiq2vi",
                "type": "Link",
                "linkType": "Entry"
              }
            }
          },
          "content": []
        },
        {
          "nodeType": "paragraph",
          "data":
          {},
          "content": [
          {
            "nodeType": "text",
            "value": "",
            "marks": [],
            "data":
            {}
          }]
        }]
      }`,
      references: [
        {
          contentful_id: '2b2viIpoblj1paxLtiq2vi',
          __typename: 'ContentfulResumeUpload',
          mobileWidget: 'Upload Widget',
          css: [
            {
              bindToField: BindToFields.Main,
              className: 'centered-2-column-variant',
              cssRules: {
                cssRules: 'display: block;',
              },
            },
          ],
        },
      ],
    },
    images: null as any,
    bottom: null as any,
  } as any

  return (
    <RoundBackgroundBlock
      {...uploadResumeData}
      sectionClass={classes.sectionPadding}
    />
  )
}

export default ResumeReviewHero

export const useStyles = makeStyles()({
  sectionPadding: {
    paddingLeft: '6%',
    paddingRight: '6%',
    '@media (min-width: 1456px)': {
      paddingLeft: 'calc((100% - 91rem)/2 + 5.5rem)',
      paddingRight: 'calc((100% - 91rem)/2 + 5.5rem)',
    },
  },
})

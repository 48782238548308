import { makeStyles } from 'tss-react/mui'

export const useResumeReviewStyles = makeStyles()((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('tablet')]: {
      marginBottom: theme.spacing(1),
    },
  },
  subtitle: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down(1024)]: {
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('tablet')]: {
      marginBottom: theme.spacing(3),
    },
  },
}))
